<template>
  <div class="grid sm:grid-cols-2 2xl:grid-cols-5 gap-8">
    <div
      class="relative shadow-[0_2px_8px_0] shadow-black/10 flex items-center justify-between rounded-5 lg:rounded-10 px-5 lg:px-10 py-5.5 lg:py-8 gap-4 overflow-hidden lg:col-span-2 col-span-full"
      :class="color === 'gray' ? 'bg-#F4F8F5' : 'bg-white'"
    >
      <div class="flex flex-col gap-4 lg:gap-5 shrink-0">
        <p class="text-tekstovyy text-6 lg:text-7 font-700 max-w-62">Присоединяйте свой ресторан</p>
        <p class="text-seryy text-4.05 leading-1.2 max-lg:max-w-43 max-w-62">Привлекайте новых посетителей и расширяйте зону продажи</p>
        <Button
          class="w-fit"
          @click="openPartnerPopup"
        >
          Подключить
        </Button>
      </div>
      <img
        class="mix-blend-darken h-50 lg:h-47 max-lg:absolute max-lg:translate-x-1/3 max-lg:right-0"
        src="/imgs/join-your-restaurant1.png"
        alt=""
      >
    </div>
    <div
      class="relative shadow-[0_2px_8px_0] shadow-black/10 flex flex-col sm:flex-row lg:items-center justify-between rounded-5 lg:rounded-10 px-5 lg:px-10 py-5.5 lg:py-8 gap-4 lg:col-span-3 col-span-full"
      :class="color === 'gray' ? 'bg-#F4F8F5' : 'bg-white'"
    >
      <div class="flex flex-col gap-4 lg:gap-5">
        <p class="text-tekstovyy text-6 lg:text-7 font-700 max-w-65">Проще чем заказать столик в ресторане</p>
        <p class="text-seryy text-4.05 leading-1.2 max-w-65">Еда уже почти у вас дома!Закажите в любимом ресторане</p>
        <Button class="w-fit">
          Установить
          <template #suffix>
            <span class="i-custom:full-arrow opacity-60 text-3.5"></span>
          </template>
        </Button>
      </div>
      <div class="flex max-sm:order-first sm:hidden lg:flex items-end h-full lg:absolute top-0 left-38% border-b border-black/10 lg:border-none w-full lg:w-80 pt-5 lg:pt-0">
        <img
          class="lg:h-115%  mx-auto"
          src="/imgs/iPhone_11_Pro_Mockup.png"
          alt=""
        >
      </div>
      <div class="flex items-center gap-2.5 lg:gap-7.5 shrink-0 lg:flex-col lg:w-25">
        <div class="flex flex-col gap-2">
          <NuxtLink to="/">
            <GooglePlay class="text-black transition-color hover:text-firmennyy" />
          </NuxtLink>
          <NuxtLink
            to="https://apps.apple.com/ru/app/sakhfood/id6505093916"
            target="_blank"
          >
            <AppStore class="text-black transition-color hover:text-firmennyy" />
          </NuxtLink>
        </div>
        <img
          width="123"
          height="123"
          class="size-21.5 lg:size-25"
          src="/imgs/qr-code.svg"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
defineProps({
  color: { type: String as PropType<'white' | 'gray'>, default: 'white' }
});
const { openPartnerPopup } = usePartnersPopup();
</script>
